import React, {useState} from "react";
import styles from "./Topbar.module.css";
import {useNavigate} from "react-router-dom";
import logoPNG from "../../assets/logoSmartWL.svg";
import {AppBar, Box, Divider, IconButton, Menu, MenuItem, Toolbar, Tooltip} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import {EAuthRole, EAuthSessionData} from "../../types/auth";
import {useTranslation} from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import notHaveSubscriptionGuard from "../../guards/notHaveSubscriptionGuard";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import HomeIcon from "@mui/icons-material/Home";

import {linksBE} from "./../../componets/Footer/Footer";
import Cookies from "js-cookie";
import {AuthApiService} from "../../services/AuthApiService";
import {toast_error} from "../../utils/custom-toast";
import {isSuper} from "@babel/types";
import {is} from "date-fns/locale";
import {LinkApiService} from "../../services/LinkService";
import { useSelector } from "react-redux";

const Topbar = ({toolbarNotVisible}: {toolbarNotVisible?: boolean}) => {
	const navigate = useNavigate();
	const {t} = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const username = useSelector((state: any) => state.user.username);
	const role = useSelector((state: any) => state.user.role);
	const haveSubscription = useSelector((state: any) => state.user.haveSubscription);
	console.log("role", role);

	const isAdmin: boolean = role === EAuthRole.ADMIN;
	const isOperator: boolean = role === EAuthRole.OPERATOR;
	let isSuperAdmin: boolean = role === EAuthRole.SUPER_ADMIN;
	let isPlatformAdmin: boolean = role === EAuthRole.PLATFORM_ADMIN;
	const authApiService = new AuthApiService();

	const tenant = sessionStorage.getItem(EAuthSessionData.TENANT);
	const [linksBE, setLinksBE] = useState<linksBE[]>([]);

	

	const linkApiService = new LinkApiService();

	const link: {
		label: string;
		click: CallableFunction;
		icon: React.JSX.Element;
		divider?: boolean;
		visible: boolean;
		disabled: boolean;
	}[] = [
		{
			label: t("TOPBAR.TOOLTIP_MENU"),
			click: () => {
				navigate("/impostazioni/profilo");
			},
			icon: <SettingsIcon />,
			visible: true,
			disabled: notHaveSubscriptionGuard(haveSubscription),
		},
		{
			label: t("TOPBAR.SUPPORT"),
			click: () => openMailClient(),
			icon: <ForwardToInboxIcon />,
			divider: true,
			visible: true,
			disabled: false,
		},
		{
			label: t("TOPBAR.TENANT"),
			click: () => openTenantUrl(),
			icon: <OpenInNewIcon />,
			divider: true,
			visible: isAdmin || isOperator,
			disabled: notHaveSubscriptionGuard(haveSubscription),
		},
		{
			label: t("TOPBAR.MANUAL"),
			click: () => downloadUserManual(),
			icon: <HelpOutlineIcon />,
			divider: true,
			visible: true,
			disabled: false,
		},
		{
			label: t("TOPBAR.LOGOUT"),
			click: () => handleLogout(),
			icon: <LogoutIcon />,
			divider: true,
			visible: isAdmin || isOperator || isSuperAdmin || isPlatformAdmin,
			disabled: false,
		},
	];

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const downloadUserManual = () => {
		linkApiService
			.linkByTenantGeneric(tenant!)
			.then((response) => {
				setLinksBE(response);

				const userManualLink = response.find((link) => link.title === "USER_MANUAL");

				if (userManualLink) {
					window.open(formatWebSiteURL(userManualLink.link), "_blank");
				} else {
					console.error("Link del manuale utente non trovato");
				}
			})
			.catch((error) => {
				console.error("Errore nel recuperare i link", error);
			});
	};

	const openTenantUrl = () => {
		let tenant = sessionStorage.getItem(EAuthSessionData.TENANT);
		let url = window.location.origin + "/" + tenant;
		window.open(formatWebSiteURL(url), "_blank");
	};

	const handleLogout = async () => {
		// const userRole = sessionStorage.getItem(EAuthSessionData.ROLE);

		// if (!userRole) {
		// 	openTenantUrl();
		// 	return; // Termina l'esecuzione della funzione per prevenire il logout
		// }
		try {
			await authApiService.logout({refreshToken: Cookies.get(EAuthSessionData.REFRESH_TOKEN_COOKIE) ?? ""});
			sessionStorage.removeItem(EAuthSessionData.ACCESS_TOKEN);
			//sessionStorage.removeItem(EAuthSessionData.REFRESH_TOKEN);
			//sessionStorage.removeItem(EAuthSessionData.AUTH_DATA);
			//Cookies.remove(EAuthSessionData.REFRESH_TOKEN_COOKIE);
			sessionStorage.removeItem(EAuthSessionData.TENANT);
			sessionStorage.removeItem("refreshStatus");
			navigate("/auth/login");
		} catch (e: any) {
			toast_error(e);
		}
	};

	const formatWebSiteURL = (url: string) => {
		if (!url.startsWith("http://") && !url.startsWith("https://")) {
			return `https://${url}`;
		}
		return url;
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const openMailClient = () => {
		const email = "supporto@smartwhistle.it";

		window.location.href = `mailto:${email}`;
	};

	return (
		<AppBar
			color="transparent"
			position="static"
			sx={{
				boxShadow: "none",
			}}
		>
			<Box marginLeft="1.5%" marginRight="1.5%">
				<Toolbar>
					<Box sx={{width: "100%"}}>
						<div className={styles.logo}>
							<img
								style={{
									cursor: "pointer",
									width: "100%",
									marginTop: "10px",
								}}
								onClick={() => navigate("/")}
								src={logoPNG}
								// src={logo}
								alt="Logo"
							/>
						</div>
					</Box>
					{!toolbarNotVisible && (
						<Box>
							<Tooltip title={t("TOPBAR.TOOLTIP_MENU")}>
								<IconButton aria-controls="menu-appbar" onClick={handleMenu}>
									<SettingsIcon fontSize="large" />
								</IconButton>
							</Tooltip>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								elevation={1}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem
									disabled
									sx={{
										minWidth: "200px",
									}}
								>
									{username ? username : t("USER")}
								</MenuItem>
								<Box marginY={1}>
									<Divider />
								</Box>
								{link
									.filter((item) => item.visible)
									.map((item, index) => (
										<div key={index}>
											{item.divider && (
												<Box marginY={1}>
													<Divider />
												</Box>
											)}
											<MenuItem
												disabled={item.disabled}
												onClick={(e) => {
													handleClose();
													if (item.click) item.click();
												}}
											>
												{item.icon}
												<Box marginLeft={1}>{item.label}</Box>
											</MenuItem>
										</div>
									))}
							</Menu>
						</Box>
					)}
				</Toolbar>
			</Box>
		</AppBar>
	);
};

export default Topbar;
