import {useParams} from "react-router-dom";
import React, {CSSProperties, useEffect, useState} from "react";
import {RegistryApiService} from "../services/RegistryApiService";
import axios from "axios";
import {API_GET_LOGO_AZIENDA} from "../api/api";

interface Props {
	fill?: string;
	width?: string;
}

function ImageComponent({imageUrl}: any) {
	const containerStyle: CSSProperties = {
		width: "100px",
		// borderRadius: "50%",
		overflow: "hidden",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// backgroundColor: "var(--grey-100)",
	};

	const imageStyle: React.CSSProperties = {
		width: "90%", // Garantire che l'immagine copra completamente il contenitore in larghezza
		height: "90%", // Garantire che l'immagine copra completamente il contenitore in altezza
		objectFit: "contain", // L'immagine sarà ridimensionata per coprire l'intero contenitore
		objectPosition: "center center", // L'immagine sarà centrata sia verticalmente che orizzontalmente
	};

	return (
		<div style={containerStyle}>
			<img src={imageUrl} alt="Immagine da API" style={imageStyle} />
		</div>
	);
}

// function LogoGenerico({fill, width}: Props) {
// 	const logoStyles = {
// 		fill: fill ?? "var(--default-color)",
// 		width: width ?? "fit-content",
// 		maxWidth: "60px",
// 		minWidth: "35px",
// 		margin: "10px 0 ",
// 	};

// 	return (
// 		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5628.000000 5628.000000" preserveAspectRatio="xMidYMid meet" style={logoStyles}>
// 			<g transform="translate(0.000000,5628.000000) scale(0.100000,-0.100000)" stroke="none">
// 				<path
// 					d="M27205 56260 c-1096 -40 -2124 -131 -3155 -281 -5117 -742 -9937
// 							-2897 -13925 -6226 -2087 -1742 -3904 -3763 -5412 -6023 -1590 -2383 -2818
// 							-5026 -3608 -7765 -893 -3097 -1244 -6294 -1050 -9555 147 -2467 625 -4921
// 							1413 -7260 575 -1705 1319 -3363 2209 -4925 1029 -1804 2254 -3490 3652 -5025
// 							575 -631 1240 -1296 1871 -1871 2307 -2101 4936 -3798 7805 -5039 2609 -1128
// 							5437 -1861 8280 -2145 411 -41 673 -61 1288 -101 86 -5 97 -8 97 -25 0 -19
// 							288 -19 14800 -19 l14799 0 3 14298 c2 7863 2 14525 0 14805 -2 431 -5 507
// 							-17 507 -18 0 -22 30 -30 190 -17 369 -73 1023 -131 1535 -578 5129 -2559
// 							9983 -5741 14073 -1044 1341 -2252 2632 -3518 3757 -2176 1934 -4625 3515
// 							-7270 4692 -2614 1164 -5397 1920 -8235 2237 -669 75 -1162 114 -2010 157
// 							-260 13 -1810 20 -2115 9z"
// 				/>
// 			</g>
// 		</svg>
// 	);
// }

function Logo({onLogoPresence, fill, width}: Props & {onLogoPresence: (isPresent: boolean) => void}) {
	const [imageData, setImageData] = useState(null);
	console.log("imageData", imageData);
	const [loading, setLoading] = useState(true);
	// const tenant = sessionStorage.getItem("tenant") ?? "";
	const {tenant} = useParams();
	const aToken = sessionStorage.getItem("aToken") ?? "";

	useEffect(() => {
		const getImage = async () => {
			try {
				const response = await axios.get(API_GET_LOGO_AZIENDA, {
					headers: {
						"x-tenant": tenant,
					},
				});
				if (response.data.code === 0) {
					setImageData(response.data.data.image);
					onLogoPresence(true);
				} else {
					setImageData(null);
					onLogoPresence(false);
					console.log(response.data.message);
				}
			} catch (error: any) {
				console.log(error);
				onLogoPresence(false);
			} finally {
				setLoading(false);
			}
		};
		getImage();
	}, []);

	if (loading) {
		return <div></div>;
	}

	return <>{imageData ? <ImageComponent imageUrl={imageData} /> : <div></div>}</>;
}

export default Logo;
