import {Alert, Autocomplete, Button, CircularProgress, FormControlLabel, Grid, Switch, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {SyntheticEvent, useState} from "react";
import {IBillingInfo} from "../../../types/payment";
import customerSchema from "../../../formModels/billingInfo/billingInfoSchema";
import europeanCountryCodes from "../../../utils/europeanCountryCodes";
import italianProvinces from "../../../utils/italianProvinces";
import {Form, Formik, FormikErrors, FormikTouched} from "formik";
import {PaymentApiService} from "../../../services/PaymentApiService";
import {IAlert} from "../../../types/commons";
import {FormikHelpers} from "formik/dist/types";

interface FormFatturazioneProps {
	initialValues: IBillingInfo;
	handleHaveBillingInfo: (have: boolean) => void;
	haveBilling?: boolean;
}

const FormFatturazione = ({initialValues, handleHaveBillingInfo, haveBilling}: FormFatturazioneProps) => {
	const {t} = useTranslation();
	const [displayField, setDisplayField] = useState<boolean>(true);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
	const schema = customerSchema;
	const countryList = europeanCountryCodes;
	const provincesList = italianProvinces;
	const paymentApiService = new PaymentApiService();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "ERRORS.BILLING_INFO.SUBMIT_ERROR",
		dismissible: true,
		onClose: () => {
			setAlert({...alert, show: false});
		},
	});

	const onChangeCountry = (
		newValue: string | null,
		setFieldValue: Function,
		setTouched: (touched: FormikTouched<IBillingInfo>, shouldValidate?: boolean) => Promise<void | FormikErrors<IBillingInfo>>,
	) => {
		setFieldValue("country", newValue);
		setTouched({country: true}).then();
		if (newValue !== "IT") {
			setFieldValue("code_sdi", "XXXXXXX");
			setFieldValue("postal_code", "00000");
			setFieldValue("billing_sdi", true);
			setFieldValue("pec", null);
			setFieldValue("state", "EE");
			setDisplayField(false);
		} else {
			setFieldValue("code_sdi", "");
			setFieldValue("postal_code", "");
			setFieldValue("billing_sdi", false);
			setFieldValue("pec", "");
			setFieldValue("state", "");
			setDisplayField(true);
		}
	};

	const onSubmit = async (values: IBillingInfo, helpers: FormikHelpers<IBillingInfo>) => {
		setIsLoadingSubmit(true);
		paymentApiService
			.saveBillingInfo(values, haveBilling)
			.then((res) => {
				handleHaveBillingInfo(true);
				haveBilling = true;
				setIsLoadingSubmit(false);
				setAlert({
					...alert,
					show: true,
					severity: "success",
					message: "COMPLETE_PROFILE.ACCORDION_1.SUBMIT_SUCCESS",
				});
				Object.keys(values).forEach((key) => {
					helpers.setTouched({[key]: false});
				});
			})
			.catch((error) => {
				setAlert({...alert, show: true, severity: "error", message: "COMPLETE_PROFILE.ACCORDION_1.SUBMIT_ERROR"});
				setIsLoadingSubmit(false);
			});
	};

	return (
		<>
			{alert.show && (
				<Alert className="mb-4" severity={alert.severity} onClose={alert.onClose}>
					{t(alert.message)}
				</Alert>
			)}
			<Formik<IBillingInfo> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
				{({handleSubmit, handleChange, values, setTouched, handleBlur, setFieldValue, touched, errors, isValid}) => {
					const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IBillingInfo>]);
					const isSubmitDisabled = !formIsTouched || !isValid;
					return (
						<Form noValidate onSubmit={handleSubmit}>
							<Grid container columnSpacing={3} rowSpacing={3}>
								<Grid item xs={12}>
									<Autocomplete
										disablePortal
										readOnly={isLoadingSubmit}
										options={countryList}
										style={{width: 200}}
										disableClearable
										value={values.country}
										onChange={(_: SyntheticEvent, newValue: string | null) => onChangeCountry(newValue, setFieldValue, setTouched)}
										renderInput={(params) => <TextField {...params} required label={t("COMPLETE_PROFILE.ACCORDION_1.COUNTRY")} />}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography component="h2" style={{fontWeight: "bold"}}>
										{t("COMPLETE_PROFILE.ACCORDION_1.ADDRESS_INFO")}
									</Typography>
								</Grid>
								<Grid item xs={12} md={displayField ? 4 : 6}>
									<TextField
										name="address"
										value={values.address}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.address && !!errors.address}
										helperText={touched.address ? t(errors.address ?? "") : null}
										inputProps={{maxLength: 100}}
										required
										label={t("COMPLETE_PROFILE.ACCORDION_1.ADDRESS")}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={displayField ? 4 : 6}>
									<TextField
										name="city"
										value={values.city}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.city && !!errors.city}
										helperText={touched.city ? t(errors.city ?? "") : null}
										inputProps={{maxLength: 50}}
										required
										label={t("COMPLETE_PROFILE.ACCORDION_1.CITY")}
										fullWidth
									/>
								</Grid>
								{displayField ? (
									<Grid item xs={12} md={2}>
										<TextField
											name="postal_code"
											value={values.postal_code}
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.postal_code && !!errors.postal_code}
											helperText={touched.postal_code ? t(errors.postal_code ?? "") : null}
											inputProps={{maxLength: 5}}
											required
											label={t("COMPLETE_PROFILE.ACCORDION_1.POSTAL_CODE")}
											fullWidth
										/>
									</Grid>
								) : null}
								{displayField ? (
									<Grid item xs={12} md={2}>
										<Autocomplete
											disablePortal
											readOnly={isLoadingSubmit}
											options={provincesList}
											fullWidth
											disableClearable
											onBlur={() => setTouched({state: true})}
											value={values.state}
											onChange={(_: SyntheticEvent, newValue: string | null) => setFieldValue("state", newValue)}
											renderInput={(params) => (
												<TextField
													{...params}
													error={touched.state && !!errors.state}
													required
													helperText={touched.state ? t(errors.state ?? "") : null}
													label={t("COMPLETE_PROFILE.ACCORDION_1.STATE")}
												/>
											)}
										/>
									</Grid>
								) : null}
								<Grid item xs={12}>
									<Typography component="h2" style={{fontWeight: "bold"}}>
										{t("COMPLETE_PROFILE.ACCORDION_1.BUSINESS_INFO")}
									</Typography>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										name="business_name"
										value={values.business_name}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.business_name && !!errors.business_name}
										helperText={touched.business_name ? t(errors.business_name ?? "") : null}
										inputProps={{maxLength: 100}}
										required
										label={t("COMPLETE_PROFILE.ACCORDION_1.BUSINESS_NAME")}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={displayField ? 3 : 6}>
									<TextField
										name="vat_number"
										value={values.vat_number}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.vat_number && !!errors.vat_number}
										helperText={touched.vat_number ? t(errors.vat_number ?? "") : null}
										inputProps={{maxLength: 15}}
										required
										label={t("COMPLETE_PROFILE.ACCORDION_1.VAT_NUMBER")}
										fullWidth
									/>
								</Grid>
								{displayField ? (
									<Grid item xs={12} md={3}>
										<TextField
											name="pec"
											value={values.pec}
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.pec && !!errors.pec}
											helperText={touched.pec ? t(errors.pec ?? "") : null}
											inputProps={{maxLength: 100}}
											required
											label={t("COMPLETE_PROFILE.ACCORDION_1.PEC")}
											fullWidth
										/>
									</Grid>
								) : null}
								{displayField ? (
									<>
										<Grid item xs={6}>
											<FormControlLabel
												control={<Switch checked={values.billing_sdi} onChange={handleChange} name="billing_sdi" />}
												label={t("COMPLETE_PROFILE.ACCORDION_1.BILLING_SDI")}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextField
												name="code_sdi"
												value={values.code_sdi ?? ""}
												onChange={(e) => {
													if (e.target.value) {
														e.target.value = e.target.value.toUpperCase().trim();
														return handleChange(e);
													}
													if (!values.billing_sdi) return setFieldValue("code_sdi", null);
												}}
												onBlur={handleBlur}
												error={touched.code_sdi && !!errors.code_sdi}
												helperText={touched.code_sdi ? t(errors.code_sdi ?? "") : null}
												inputProps={{maxLength: 7}}
												required={values.billing_sdi}
												label={t("COMPLETE_PROFILE.ACCORDION_1.CODE_SDI")}
												fullWidth
											/>
										</Grid>
									</>
								) : null}
								<Grid item xs={12}>
									<Typography component="h2" style={{fontWeight: "bold"}}>
										{t("COMPLETE_PROFILE.ACCORDION_1.OTHER_INFO")}
									</Typography>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										name="email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.email && !!errors.email}
										helperText={touched.email ? t(errors.email ?? "") : null}
										inputProps={{maxLength: 100}}
										required
										label={t("COMPLETE_PROFILE.ACCORDION_1.EMAIL")}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										name="phone"
										value={values.phone}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.phone && !!errors.phone}
										helperText={touched.phone ? t(errors.phone ?? "") : null}
										inputProps={{maxLength: 13}}
										required
										label={t("COMPLETE_PROFILE.ACCORDION_1.PHONE")}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} textAlign="end">
									<Button
										type="submit"
										variant="contained"
										disableElevation
										color="secondary"
										// disabled={isLoadingSubmit || isSubmitDisabled}
										size="large"
										startIcon={isLoadingSubmit ? <CircularProgress color="secondary" size={15} /> : null}
									>
										{t("COMPLETE_PROFILE.ACCORDION_1.SUBMIT")}
									</Button>
								</Grid>
							</Grid>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default FormFatturazione;
