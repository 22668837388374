import {Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {radioStyleSX} from "../../../assets/CustomStyles";
import styles from "./TabCinque.module.css";
import {t} from "i18next";

import {EContactType, ERegistryAddressType, ERegistryType} from "../../../types/registry";

import registryValidationSchema from "./FormModels/registryValidationSchema";
import {Form, Formik} from "formik";

import {LinkApiService} from "../../../services/LinkService";
import {findLinkByTitle} from "../../../utils/helperFunctions";

interface IRegistrySegnalatore {
	identification_data: boolean;
	firstname: string;
	surname: string;
	registry_type: ERegistryType.SEGNALATORE;
	birth_place: string;
	birth_date: string;
	registry_address_type: string;
	address: string;
	number: string;
	postal_code: string;
	city: string;
	province: string;
	state: string;
	fiscal_code: string;
	email: string;
	phone: string;
}

export interface linksBE {
	_id: string;
	title: string;
	link: string;
	generic: boolean;
	lang: string;
	createdAt: string;
	updatedAt: string;
}

const TabCinque = ({updateReportField, handleTabCompletion, tabIndex}: any) => {
	const registryIntialState: any = {
		firstname: "",
		surname: "",
		registry_type: ERegistryType.SEGNALATORE,
		birth_place: "",
		birth_date: "",
		registry_address_type: "",
		address: "",
		number: "",
		postal_code: "",
		city: "",
		province: "",
		state: "",
		fiscal_code: "",
		email: "",
		phone: "",
	};

	const [isIdentified, setIsIdentified] = useState<boolean>(false);
	const [registry, setRegistry] = useState<any>(registryIntialState);
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
	const [linksBE, setLinksBE] = useState<linksBE[]>([]);
	const tenant = sessionStorage.getItem("tenant");

	const linkApiService = new LinkApiService();

	const schema = registryValidationSchema;

	const handleAnonReportChange = (event: React.ChangeEvent<HTMLInputElement>, setValues: any, setTouched: any) => {
		const newValue = event.target.value === "true";
		setIsIdentified(newValue);

		if (newValue) {
			updateReportField({identification_data: newValue, registry: registryIntialState});
		} else {
			updateReportField({identification_data: newValue, registry: undefined});
			setRegistry(registryIntialState);
			setValues(registryIntialState);
			setTouched({});
		}
	};

	const extractToponAndAddress = (addressString: string) => {
		const addressArray = addressString.trim().split(" ");
		const topon = addressArray[0];
		const address = addressArray.slice(1).join(" ");

		return {topon, address};
	};

	useEffect(() => {
		linkApiService.linkByTenantPrivacy(tenant!).then((response) => {
			setLinksBE(response);
		});
	}, []);

	const acceptComponent = () => {
		const privacyPolicyLink = findLinkByTitle("PRIVACY_POLICY", linksBE);
		const personalDataProtectionLink = findLinkByTitle("PROTECTION_OF_PERSONAL_DATA", linksBE);

		return (
			<Box color="black">
				{t("SIGN_UP.ACCEPT_5")}
				<a style={{textDecoration: "underline"}} target="_blank" href={privacyPolicyLink} rel="noreferrer">
					{t("SIGN_UP.ACCEPT_2")}
				</a>
				{t("SIGN_UP.ACCEPT_6")}
				<a style={{textDecoration: "underline"}} href={privacyPolicyLink} target="_blank" rel="noreferrer">
					{t("SIGN_UP.ACCEPT_7")}
				</a>
			</Box>
		);
	};

	const customHandleChange = (event: React.ChangeEvent<HTMLInputElement>, handleChange: (event: React.ChangeEvent<any>) => void) => {
		const {name, value} = event.target;
		setRegistry((prevState: any) => {
			const updatedRegistry = {
				...prevState,
				[name]: value,
			};
			if (event.currentTarget) {
				const form = event.currentTarget.form;
				if (form) {
					const addressInput = form.elements.namedItem("address") as HTMLInputElement;
					if (addressInput) {
						const {topon, address} = extractToponAndAddress(addressInput.value);
						updatedRegistry.registry_address = [
							{
								registry_address_type: ERegistryAddressType.RESIDENZA,
								topon: topon,
								street: address,
								number: parseInt(updatedRegistry.number),
								postal_code: updatedRegistry.postal_code,
								city: updatedRegistry.city,
								province: updatedRegistry.province,
								state: updatedRegistry.state,
							},
						];
					}
				}
			}
			updatedRegistry.contact = [
				{
					contact_type: EContactType.EMAIL,
					contact: updatedRegistry.email,
				},
				{
					contact_type: EContactType.PHONE,
					contact: updatedRegistry.phone,
				},
			];

			updateReportField({
				registry: updatedRegistry,
			});

			return updatedRegistry;
		});
		handleChange(event);
	};

	useEffect(() => {
		if (!isIdentified) {
			setIsCheckboxChecked(false);
			handleTabCompletion(tabIndex, true);
			return;
		}

		if (!isCheckboxChecked) {
			handleTabCompletion(tabIndex, false);
			return;
		}

		schema
			.validate(registry, {abortEarly: false})
			.then(() => {
				const isRegistryComplete = !!(
					isIdentified &&
					registry.firstname &&
					registry.surname &&
					registry.birth_place &&
					registry.birth_date &&
					registry.address &&
					registry.number &&
					registry.postal_code &&
					registry.city &&
					registry.province &&
					registry.state &&
					registry.fiscal_code &&
					registry.email &&
					registry.phone
				);

				const allFieldsFilled = isIdentified ? isRegistryComplete : true;

				if (allFieldsFilled || !isIdentified) {
					handleTabCompletion(tabIndex, true);
				} else {
					handleTabCompletion(tabIndex, false);
				}
			})
			.catch((err) => {
				handleTabCompletion(tabIndex, false);
			});
	}, [
		isIdentified,
		isCheckboxChecked,
		tabIndex,
		handleTabCompletion,
		isIdentified,
		registry.firstname,
		registry.surname,
		registry.birth_place,
		registry.birth_date,
		registry.address,
		registry.number,
		registry.postal_code,
		registry.city,
		registry.province,
		registry.state,
		registry.fiscal_code,
		registry.email,
		registry.phone,
	]);

	const labelStyle = isIdentified ? styles.label : styles.labelDisabled;
	const textFieldStyle = isIdentified ? styles.textField : styles.textFieldDisabled;

	return (
		<Formik<IRegistrySegnalatore> initialValues={registryIntialState} validationSchema={schema} onSubmit={(values) => {}} enableReinitialize>
			{({handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setTouched}) => {
				return (
					<Form>
						<RadioGroup name="period" value={String(isIdentified)} onChange={(e) => handleAnonReportChange(e, setValues, setTouched)} row>
							<FormControlLabel value="true" control={<Radio sx={radioStyleSX} />} label={t("YES")} />
							<FormControlLabel value="false" control={<Radio sx={radioStyleSX} />} label={t("NO")} />
						</RadioGroup>

						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<div className={labelStyle}>{t("NAME")}</div>
								<TextField
									name="firstname"
									variant="outlined"
									className={textFieldStyle}
									fullWidth
									value={values.firstname}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.firstname && !!errors.firstname}
									helperText={touched.firstname && t(errors.firstname ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<div className={labelStyle}>{t("SURNAME")}</div>
								<TextField
									name="surname"
									variant="outlined"
									className={textFieldStyle}
									fullWidth
									value={values.surname}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.surname && !!errors.surname}
									helperText={touched.surname && t(errors.surname ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<div className={labelStyle}>{t("BIRTH_PLACE")}</div>
								<TextField
									name="birth_place"
									variant="outlined"
									className={textFieldStyle}
									fullWidth
									value={values.birth_place}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.birth_place && !!errors.birth_place}
									helperText={touched.birth_place && t(errors.birth_place ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<div className={labelStyle}>{t("BIRTH_DATE")}</div>
								<TextField
									name="birth_date"
									type="date"
									variant="outlined"
									className={textFieldStyle}
									fullWidth
									value={values.birth_date}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.birth_date && !!errors.birth_date}
									helperText={touched.birth_date && t(errors.birth_date ?? "")}
								/>
							</Grid>

							{/* Address */}
							<Grid item xs={12} sm={7}>
								<div className={labelStyle}>{t("ADDRESS")}</div>
								<TextField
									name="address"
									type="text"
									className={textFieldStyle}
									fullWidth
									value={values.address}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.address && !!errors.address}
									helperText={touched.address && t(errors.address ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<div className={labelStyle}>{t("NUMBER")}</div>

								<TextField
									name="number"
									type="number"
									className={textFieldStyle}
									fullWidth
									value={values.number}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.number && !!errors.number}
									helperText={touched.number && t(errors.number ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<div className={labelStyle}>{t("POSTAL_CODE")}</div>
								<TextField
									name="postal_code"
									type="number"
									className={textFieldStyle}
									fullWidth
									value={values.postal_code}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.postal_code && !!errors.postal_code}
									helperText={touched.postal_code && t(errors.postal_code ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<div className={labelStyle}>{t("CITY")}</div>
								<TextField
									name="city"
									type="text"
									className={textFieldStyle}
									fullWidth
									value={values.city}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.city && !!errors.city}
									helperText={touched.city && t(errors.city ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<div className={labelStyle}>{t("PROVINCE")}</div>
								<TextField
									name="province"
									type="text"
									className={textFieldStyle}
									fullWidth
									value={values.province}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.province && !!errors.province}
									helperText={touched.province && t(errors.province ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<div className={labelStyle}>{t("COUNTRY")}</div>
								<TextField
									name="state"
									type="text"
									className={textFieldStyle}
									fullWidth
									value={values.state}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.state && !!errors.state}
									helperText={touched.state && t(errors.state ?? "")}
								/>
							</Grid>
							{/* End of Address */}

							<Grid item xs={12} sm={12}>
								<div className={labelStyle}>{t("FISCAL_CODE")}</div>
								<TextField
									name="fiscal_code"
									variant="outlined"
									className={textFieldStyle}
									fullWidth
									value={values.fiscal_code.toUpperCase()}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.fiscal_code && !!errors.fiscal_code}
									helperText={touched.fiscal_code && t(errors.fiscal_code ?? "")}
								/>
							</Grid>
							{/* Contact */}
							<Grid item xs={12} sm={6}>
								<div className={labelStyle}>Email</div>
								<TextField
									name="email"
									variant="outlined"
									className={textFieldStyle}
									fullWidth
									value={values.email}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									error={touched.email && !!errors.email}
									helperText={touched.email && t(errors.email ?? "")}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<div className={labelStyle}>{t("PHONE")}</div>
								<TextField
									name="phone"
									variant="outlined"
									className={textFieldStyle}
									fullWidth
									value={values.phone}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => customHandleChange(e, handleChange)}
									onBlur={handleBlur}
									disabled={!isIdentified}
									required
									type="tel"
									// type="text"
									error={touched.phone && !!errors.phone}
									helperText={touched.phone && t(errors.phone ?? "")}
								/>
							</Grid>
							{/* End of Contact */}
							<Grid item xs={12}>
								<FormControlLabel
									control={<Checkbox checked={isCheckboxChecked} onChange={(e) => setIsCheckboxChecked(e.target.checked)} />}
									disabled={!isIdentified}
									label={acceptComponent()}
								/>
							</Grid>
						</Grid>
					</Form>
				);
			}}
		</Formik>
	);
};

export default TabCinque;
