import {IApiCommonsFields} from "./commons";
import {EAuthRole} from "./auth";

export enum ERegistryType {
	DIPENDENTE = "DIPENDENTE",
	SEGNALATORE = "SEGNALATORE",
	AZIENDA = "AZIENDA",
}

export enum EContactType {
	EMAIL = "EMAIL",
	PHONE = "PHONE",
	SITO_WEB = "SITO WEB",
	FACEBOOK = "FACEBOOK",
	INSTAGRAM = "INSTAGRAM",
	LINKEDIN = "LINKEDN",
	TWITTER = "TWITTER",
}

export enum ERegistryAddressType {
	LEGALE = "LEGALE",
	LEGALE_PRINCIPALE = "LEGALE PRINCIPALE",
	RESIDENZA = "RESIDENZA",
	RESIDENZA_PRINCIPALE = "RESIDENZA PRINCIPALE",
}

export interface IRegistryOperator {
	firstname: string;
	surname: string;
	fiscal_code: string;
	email: string;
	phone: string;
}

export interface IRegistry extends IApiCommonsFields {
	id_registry_owner: IRegistryOwner | string;
	id_registry_type: IRegistryType[];
	registry_type: ERegistryType;
	registry_address: string[];
	firstname: string;
	surname: string;
	fiscal_code: string;
	profession: string;
	id_contact: IContact[];
	enabled: boolean;
	id_registry_address: [];
	tenant: string;
	key_code: string;
	image: string;
	id_auth_module: string;
	isManual: boolean;
	user: IRegistryUser;
	contact: IRegistryContact[];
	business_name?: string;
	vat_number?: string;
}

export interface IRegistryOwner extends IApiCommonsFields {
	id_registry_owner: string;
	id_registry_type: IRegistryType[];
	business_name: string;
	website: string;
	id_contact: IIdRegistryContact[];
	id_registry_address: IRegistryAddress[];
}

export interface IRegistryUser {
	email: string;
	applicationsEnabled: "wb"[];
	role?: EAuthRole;
}

export interface IRegistryType extends IApiCommonsFields {
	code: ERegistryType;
	description: string;
	is_auth_enabled: boolean;
}

export interface IIdRegistryContact extends IApiCommonsFields {
	id_contact_type: string;
	contact: string;
}

export interface IRegistryContact extends IApiCommonsFields {
	contact_type: EContactType;
	contact: string;
}

export interface IContact {
	_id: string;
	id_contact_type: {
		_id: string;
		code: string;
	};
	contact: string;
}

export interface IContactType extends IApiCommonsFields {
	code: string;
	description: string;
}

export interface IOperator extends IApiCommonsFields {
	firstname: string;
	surname: string;
	id_contact: IContact[];
	id_registry_address: [];
}

export interface IRegistryAddress extends IApiCommonsFields {
	street: string;
	id_registry_address: string;
	city: string;
	postal_code: string;
	province: string;
	state: string;
}

export interface IRegistryProfileInitialValues {
	firstname: string;
	surname: string;
	phone: string;
	email?: string;
	business_name?: string;
	id_registry_owner?: IRegistryOwner;
}

export interface IRegistryCompanyInitialValues {
	business_name: string;
	website: string;
	postal_code: string;
	city: string;
	province: string;
	country: string;
	street: string;
}

export interface IRegistryCompanyMeRequest {
	company: {
		business_name: string;
		website: string;
		address: {
			street: string;
			postal_code: string;
			city: string;
			province: string;
			state: string;
		};
	};
}

export interface IRegistryChangePasswordInitialValues {
	newPassword: string;
	confirmPassword: string;
}

export interface IRegistryAzienda extends IApiCommonsFields {
	id_registry_owner: string;
	id_registry_type: IRegistryType[];
	business_name: string;
	vat_number: string;
	id_contact: IRegistryContactAzienda[];
	tenant: string;
	key_code: string;
	isManual: boolean;
}

export interface IRegistryContactAzienda extends IApiCommonsFields {
	id_contact_type: IRegistryContactTypeAzienda;
	contact: string;
}

export interface IRegistryContactTypeAzienda extends IApiCommonsFields {
	code: EContactType;
	description: string;
}

export interface IImageTenant {
	image: string;
}
