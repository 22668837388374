import React, {useEffect, useState} from "react";
import styles from "./LandingPage.module.css";
import {Box, CircularProgress, Container, Grid} from "@mui/material";
import Logo from "../../assets/logo";
import VisualizzazioneAnonima from "./VisualizzazioneAnonima";
import {useNavigate, useParams} from "react-router-dom";
import {toast_error} from "../../utils/custom-toast";
import {ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import logoPNG from "../../assets/logoSmartWL.svg";
import {AuthApiService} from "../../services/AuthApiService";
import {IValidateTenant} from "../../types/auth";

const LandingPage: React.FC = () => {
	const {tenant} = useParams();
	const {t} = useTranslation();
	const [tenantName, setTenantName] = useState<string>("");
	const [logoPresent, setLogoPresent] = useState(false);

	if (tenant) {
		sessionStorage.setItem("tenant", tenant);
	}
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const authApiService = new AuthApiService();

	const handleLogoPresence = (isPresent: any) => {
		setLogoPresent(isPresent);
	};

	useEffect(() => {
		const checkTenant = async () => {
			const requestbody: IValidateTenant = {
				code: tenant ?? "",
			};
			try {
				const response = await authApiService.validateTenant(requestbody);
				if (response) {
					if (response.name) {
						setTenantName(response.name);
						sessionStorage.setItem("tenantName", response.name);
					}
					setIsLoading(false);
				} else {
					navigate("*");
				}
			} catch (error: any) {
				navigate("*");
				toast_error(error);
			}
		};
		checkTenant();
	}, [navigate, tenant]);

	return (
		<>
			<ToastContainer />
			{isLoading ? (
				<div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
					<CircularProgress />
				</div>
			) : (
				<div>
					<Grid container className={styles.container}>
						<Grid item xs={12} sm={6} className={styles.primarySide}>
							<Container>
								<Box sx={{alignItems: "center", display: "flex", flexDirection: "column"}}>
									<div className={styles.logo}>
										<img
											style={{width: "100%"}}
											src={logoPNG}
											// src={logo}
											alt="Logo"
										/>
										{/* <Logo /> */}
									</div>

									{/* <div className={styles.titleContainer}><h1 className={`${styles.title} `}>Smart Whistle</h1></div> */}
								</Box>
							</Container>
						</Grid>
						<Grid item xs={12} sm={6} className={styles.whiteSide}>
							<Container maxWidth="sm">
								{/* <ColorChangerButton /> */}
								{logoPresent && (
									<Box marginBottom={"200px"}>
										<Logo width="60%" fill="#EEEEEE" onLogoPresence={setLogoPresent} />
									</Box>
								)}
								<div className={styles.labels}>{t("LANDINGPAGE.LABEL_SEND_REPORT", {tenantName})}</div>
								<VisualizzazioneAnonima />
							</Container>
						</Grid>
					</Grid>
				</div>
			)}
		</>
	);
};
export default LandingPage;
