import {createTheme} from "@mui/material";

export const colors = {
	primary: "#002FD0",
	secondary: "#4c7fff",
	tertiary: "#EEEEEE",
	black: "#0A0A0A",
	divider: "#bdbdbd",
	white: "#fff",
};

const heading = {
	fontWeight: 700,
	color: colors.black,
};

const theme = createTheme({
	palette: {
		primary: {
			main: colors.primary,
			contrastText: colors.white,
			light: colors.primary,
			dark: colors.primary,
		},
		secondary: {
			main: colors.black,
			contrastText: colors.white,
		},
		text: {
			primary: colors.black,
		},
	},
	typography: {
		fontFamily: ["'Ubuntu', sans-serif"].join(","),
		h1: {
			...heading,
		},
		h2: {
			...heading,
		},
		h3: {
			...heading,
		},
		h4: {
			...heading,
		},
		h5: {
			...heading,
		},
		h6: {
			...heading,
		},
		subtitle1: {
			...heading,
		},
		subtitle2: {
			...heading,
		},
	},
	components: {
		MuiCard: {
			defaultProps: {},
			styleOverrides: {
				root: {
					borderRadius: "10px",
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: colors.divider,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontWeight: 700,
					color: colors.black,
					fontSize: "14px",
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					borderColor: colors.divider,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "10px",
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					":first-of-type": {
						borderTopLeftRadius: "10px",
						borderTopRightRadius: "10px",
					},
					"&:last-of-type": {
						borderBottomLeftRadius: "10px",
						borderBottomRightRadius: "10px",
					},
					"&.Mui-expanded": {},
				},
			},
		},
	},
});

export default theme;
