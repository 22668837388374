import React, {ReactNode} from "react";
import Topbar from "../Topbar/Topbar";
import {Box, Container, Divider, Grid, Tab, Tabs, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import isAdminGuard from "../../guards/isAdminGuard";
import isManualGuard from "../../guards/isManualGuard";
import Footer from "../../componets/Footer/Footer";
import { useSelector } from "react-redux";

interface IProps {
    children?: ReactNode;
}

const ImpostazioniPage = ({children}: IProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const role = useSelector((state: any) => state.user.role);
    const path = pathname.split("/")[2] as TPage;
    const link: { name: TPage, label: string, click: () => void, visible: boolean }[] = [
        {
            name: "profilo",
            label: t('TOPBAR.PROFILE'),
            click: () => {
                navigate("/impostazioni/profilo");
            },
            visible: true
        },
        /*{
            name: "azienda",
            label: t('TOPBAR.COMPANY'),
            click: () => {
                navigate("/impostazioni/azienda");
            },
            visible: (isAdminGuard() && isManualGuard())
        },*/
        {
            name: "piani-fatturazione",
            label: t('TOPBAR.BILLING'),
            click: () => {
                navigate("/impostazioni/piani-fatturazione");
            },
            visible: (isAdminGuard(role) && !isManualGuard(role))
        },
        {
            name: "utenti",
            label: t('TOPBAR.USERS'),
            click: () => {
                navigate("/impostazioni/utenti");
            },
            visible: isAdminGuard(role)
        },
    ];

    let tabIndex = link.filter(item => item.visible).findIndex((item) => item.name === path);

    if (tabIndex === -1) {
        tabIndex = 0;
    }
    return (
        <>
            <Grid sx={{mt: 1}}>
                <Topbar />
            </Grid>
            <Container maxWidth="xl">
                <Box marginY={3}>
                    <Typography variant="h4" component="h1">
                        {link.filter(item => item.visible)[tabIndex]?.label}
                    </Typography>
                </Box>
                <Tabs value={tabIndex} sx={{width: '100%'}}>
                    {link.filter(item => item.visible).map((item, index) => (
                        <Tab key={index} component="div" label={item.label} onClick={item.click}/>))}
                </Tabs>
                <Divider/>
                <Box marginTop={3}>
                    <Outlet/>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}
export default ImpostazioniPage

declare type TPage = "profilo" | "azienda" | "piani-fatturazione" | "utenti";
