import {useCallback, useState} from "react";
import styles from "./FormSegnalazioni.module.css";
import FormAccordion from "../../compontensNuovi/Accordion/FormAccordion";

import BasicButton from "../../compontensNuovi/buttons/BasicButton";

import TabUno from "./1tab_uno/TabUno";
import TabDue from "./2tab_due/TabDue";
import TabTre from "./3tab_tree/TabTre";
import TabQuattro from "./4tab_quattro/TabQuattro";
import TabSeiModale from "./6tab_sei/TabSeiModale";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import {toast_error, toast_success} from "../../utils/custom-toast";
import {ToastContainer} from "react-toastify";
import {Box, Container} from "@mui/material";
import BackButton from "../../componets/Buttons/BackButton";
import {ERegistryType} from "../../types/registry";
import TabCinqueNew from "./5tab_cinque_new/TabCinqueNew";
import {CoreApiService} from "../../services/CoreApiService";
import Footer from "../../componets/Footer/Footer";

interface Report {
	tenant: string;
	report_type: any;
	other_report_type: boolean;
	other_report_type_description: string;
	time_period_ended: boolean;
	start_date: string;
	end_date: string;
	subject_involved: SubjectInvolved[];
	description: string;
	other_subjects_can_report_facts: boolean;
	other_subjects_can_report_facts_description: string;
	report_another_authority: boolean;
	report_another_authority_description: string;
	talk_with_someone_about_what_happened: boolean;
	talk_with_someone_about_what_happened_description: string;
	identification_data: boolean;
	registry: Registry;
}

interface SubjectInvolved {
	subject_involved_type_code?: string;
	subject_involved?: string;
}

interface Registry {
	firstname: string;
	surname: string;
	birth_place: string;
	birth_date: string;
	registry_address: RegistryAddress[] | undefined;
	fiscal_code: string;
	contact: Contact[];
	tenant: string;
}

interface RegistryAddress {
	registry_address_type: string;
	topon: string;
	street: string;
	number: number;
	postal_code: string;
	city: string;
	province: string;
	state: string;
}

interface Contact {
	contact_type: string;
	contact: string;
}

const FormSegnalazioni = () => {
	const tenant = sessionStorage.getItem("tenant") ?? "";
	const reportIntialState: Report = {
		tenant: tenant,
		report_type: {},
		other_report_type: false,
		other_report_type_description: "",
		time_period_ended: false,
		start_date: "",
		end_date: "",
		subject_involved: [],
		description: "",
		other_subjects_can_report_facts: false,
		other_subjects_can_report_facts_description: "",
		report_another_authority: false,
		report_another_authority_description: "",
		talk_with_someone_about_what_happened: false,
		talk_with_someone_about_what_happened_description: "",
		identification_data: false,
		registry: {
			firstname: "",
			surname: "",
			birth_place: "",
			birth_date: "",
			registry_address: [
				{
					registry_address_type: "",
					topon: "",
					street: "",
					number: NaN,
					postal_code: "",
					city: "",
					province: "",
					state: "",
				},
			],
			fiscal_code: "",
			contact: [
				{
					contact_type: "",
					contact: "",
				},
			],
			tenant: tenant,
		},
	};

	const [report, setReport] = useState<Report>(reportIntialState);

	const [tabCompletion, setTabCompletion] = useState<boolean[]>(Array(6).fill(false));

	const [reportId, setReportId] = useState<string>("");

	const [reportZipFile, setReportZipFile] = useState<Blob | null>(null);

	const navigate = useNavigate();
	const coreApiService = new CoreApiService();

	const updateReportField = useCallback(
		(values: any) => {
			setReport((prevReport) => ({
				...prevReport,
				...values,
			}));
		},
		[setReport],
	);

	const handleTabCompletion = useCallback(
		(tabIndex: number, isComplete: boolean) => {
			setTabCompletion((prevTabCompletion) => {
				const newTabCompletion = [...prevTabCompletion];
				newTabCompletion[tabIndex] = isComplete;
				return newTabCompletion;
			});
		},
		[setTabCompletion],
	);

	const handleFileUpload = (zipFile: Blob) => {
		setReportZipFile(zipFile);
	};

	const tabs = [
		{
			title: `1/6 - ${t("FORM_REPORTS.FIRST_TAB.MAIN_TITLE")}`,
			component: <TabUno updateReportField={updateReportField} handleTabCompletion={handleTabCompletion} tabIndex={0} />,
			activeTitle: `${t("FORM_REPORTS.FIRST_TAB.TITLE_ACTIVE")}`,
		},
		{
			title: `2/6 - ${t("FORM_REPORTS.THIRD_TAB.MAIN_TITLE")}`,
			component: <TabTre updateReportField={updateReportField} handleTabCompletion={handleTabCompletion} tabIndex={1} />,
			activeTitle: `${t("FORM_REPORTS.THIRD_TAB.TITLE_ACTIVE")}`,
		},
		{
			title: `3/6 - ${t("FORM_REPORTS.SECOND_TAB.MAIN_TITLE")}`,
			component: <TabDue updateReportField={updateReportField} handleTabCompletion={handleTabCompletion} tabIndex={2} />,
			activeTitle: `${t("FORM_REPORTS.SECOND_TAB.TITLE_ACTIVE")}`,
		},

		{
			title: `4/6 - ${t("FORM_REPORTS.FOURTH_TAB.MAIN_TITLE")}`,
			component: <TabQuattro updateReportField={updateReportField} handleTabCompletion={handleTabCompletion} tabIndex={3} />,
			activeTitle: `${t("FORM_REPORTS.FOURTH_TAB.TITLE_ACTIVE")}`,
		},
		{
			title: `5/6 - ${t("FORM_REPORTS.FIFTH_TAB.MAIN_TITLE")}`,
			// component: <TabCinque updateReportField={updateReportField} handleTabCompletion={handleTabCompletion}
			component: <TabCinqueNew updateReportField={updateReportField} handleTabCompletion={handleTabCompletion} tabIndex={4} />,
			activeTitle: `${t("FORM_REPORTS.FIFTH_TAB.TITLE_ACTIVE")}`,
		},
		{
			title: `6/6 - ${t("FORM_REPORTS.SIXTH_TAB.MAIN_TITLE")}`,
			// component: <TabSei handleFileUpload={handleFileUpload} handleTabCompletion={handleTabCompletion} tabIndex={5} />,
			component: <TabSeiModale handleFileUpload={handleFileUpload} handleTabCompletion={handleTabCompletion} tabIndex={5} />,
			activeTitle: `${t("FORM_REPORTS.SIXTH_TAB.TITLE_ACTIVE")}`,
		},
	];

	// FUNZIONE PER CONTROLLARE LO ZIP INVIATO
	// const downloadBlob = (blob: Blob, filename: string) => {
	// 	const url = window.URL.createObjectURL(blob);
	// 	const a = document.createElement("a");
	// 	a.href = url;
	// 	a.download = filename;
	// 	a.click();
	// 	window.URL.revokeObjectURL(url);
	// };

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// let subjectInvolved = report.subject_involved;
		// if (report.subject_involved[0].subject_involved_type_code === "" && report.subject_involved[0].subject_involved === "") {
		// 	subjectInvolved = [];
		// }

		let requestBody;
		if (report.identification_data) {
			requestBody = {
				tenant: tenant,
				report_type: report.report_type,
				other_report_type: report.other_report_type,
				other_report_type_description: report.other_report_type_description,
				time_period_ended: report.time_period_ended,
				start_date: report.start_date,
				end_date: report.end_date,
				subject_involved: report.subject_involved,
				description: report.description,
				other_subjects_can_report_facts: report.other_subjects_can_report_facts,
				other_subjects_can_report_facts_description: report.other_subjects_can_report_facts_description,
				report_another_authority: report.report_another_authority,
				report_another_authority_description: report.report_another_authority_description,
				talk_with_someone_about_what_happened: report.talk_with_someone_about_what_happened,
				talk_with_someone_about_what_happened_description: report.talk_with_someone_about_what_happened_description,
				identification_data: report.identification_data,
				...(report.registry
					? {
							registry: {
								registry_type: ERegistryType.SEGNALATORE,
								firstname: report.registry.firstname,
								surname: report.registry.surname,
								birth_place: report.registry.birth_place,
								birth_date: report.registry.birth_date,
								registry_address: report.registry.registry_address
									? [
											{
												registry_address_type: report.registry.registry_address[0].registry_address_type,
												topon: report.registry.registry_address[0].topon,
												street: report.registry.registry_address[0].street,
												number: report.registry.registry_address[0].number,
												postal_code: report.registry.registry_address[0].postal_code,
												city: report.registry.registry_address[0].city,
												province: report.registry.registry_address[0].province,
												state: report.registry.registry_address[0].state,
											},
									  ]
									: undefined,
								fiscal_code: report.registry.fiscal_code,
								contact: report.registry.contact
									? [
											{
												contact_type: report.registry.contact[0].contact_type,
												contact: report.registry.contact[0].contact,
											},
											...(report.registry.contact.length > 1
												? [
														{
															contact_type: report.registry.contact[1].contact_type,
															contact: report.registry.contact[1].contact,
														},
												  ]
												: []),
									  ]
									: undefined,
							},
					  }
					: {
							registry: undefined,
					  }),
			};
		} else {
			requestBody = {
				tenant: tenant,
				report_type: report.report_type,
				other_report_type: report.other_report_type,
				other_report_type_description: report.other_report_type_description,
				time_period_ended: report.time_period_ended,
				start_date: report.start_date,
				end_date: report.end_date,
				subject_involved: report.subject_involved,
				description: report.description,
				other_subjects_can_report_facts: report.other_subjects_can_report_facts,
				other_subjects_can_report_facts_description: report.other_subjects_can_report_facts_description,
				report_another_authority: report.report_another_authority,
				report_another_authority_description: report.report_another_authority_description,
				talk_with_someone_about_what_happened: report.talk_with_someone_about_what_happened,
				talk_with_someone_about_what_happened_description: report.talk_with_someone_about_what_happened_description,
				identification_data: report.identification_data,
			};
		}

		const tuttiTrue = tabCompletion.every((element) => element);

		if (tuttiTrue) {
			try {

				const response = await coreApiService.createReportFull(requestBody);

				if (response) {
					sessionStorage.setItem("keyCode", response.key_code);
					setReportId(response._id ?? '');
					navigate("/segnalazione/riepilogo");

					if (reportZipFile && response._id) {
						uploadFile(reportZipFile, response._id);
						// downloadBlob(reportZipFile, "filename.zip");
					}
				}
			} catch (error: any) {
				toast_error(error);
			}
		} else {
			toast_error("Non tutti i campi sono stati compilati");
		}
	};

	const uploadFile = async (file: Blob, reportId: string) => {
		const formData = new FormData();
		formData.append("attachment", file, "filename.ext");
		try {

			const response = await coreApiService.uploadFile(reportId, formData);
			if (response) {
				toast_success("File caricato correttamente");
			}
		} catch (error: any) {
			console.error(error);
			toast_error(`Errore: ${error}`);
		}
	};

	const handleBackClick = () => {
		navigate(`/${tenant}`);
	};

	return (
		<>
			<ToastContainer />
			{/* <ColorChangerButton /> */}

			<form onSubmit={handleSubmit}>
				<div className={styles.container}>
					<Container sx={{marginBottom: "2rem", marginTop: "2rem"}}>
						<Box marginBottom="30px" marginTop="20px">
							<BackButton onClick={handleBackClick} />
						</Box>
						<div className={styles.header}>
							<div className={styles.title}>{t("FORM_REPORTS.TITLE")}</div>
						</div>
						<FormAccordion tabs={tabs} tabCompletion={tabCompletion} />
						{/* TODO: aggiugere load
						 */}
						<BasicButton
							text={t("FORM_REPORTS.LABEL_BUTTON_SEND")}
							onClick={() => handleSubmit}
							backgroundColor={tabCompletion.every((element) => element === true) ? undefined : "var(--grey-200)"}
							borderColor={tabCompletion.every((element) => element === true) ? undefined : "var(--grey-400)"}
							color={tabCompletion.every((element) => element === true) ? undefined : "var(--grey-500)"}
							disabled={tabCompletion.every((element) => element === true) ? undefined : true}
						/>
					</Container>
					<Footer />
				</div>
			</form>
		</>
	);
};
export default FormSegnalazioni;
