import {Box, Container, Grid} from "@mui/material";
import styles from "./AuthDefaultLayout.module.css";
import React, {ReactNode} from "react";
import logoPNG from "../../../../assets/logoSmartWL.svg";

type TProps = {
	children: ReactNode;
};

const AuthDefaultLayout: React.FC<TProps> = ({children}) => {
	return (
		<div>
			<Grid container className={styles.container}>
				<Grid item xs={12} sm={6} className={styles.primarySide}>
					<Container>
						{/* <div className={styles.titleContainer}>
								<h1 className={`${styles.title} text-default-black`}>whistlebase</h1>
							</div> */}
						<Box sx={{alignItems: "center", display: "flex", flexDirection: "column"}}>
							<div className={styles.logo}>
								<img
									style={{width: "100%"}}
									src={logoPNG}
									// src={logo}
									alt="Logo"
								/>
								{/* <Logo /> */}
							</div>
							{/* <div className={styles.titleContainer}><h1 className={`${styles.title} `}>Smart Whistle</h1></div> */}
						</Box>
					</Container>
				</Grid>
				<Grid item xs={12} sm={6} className={styles.whiteSide}>
					<Container maxWidth="xs">{children}</Container>
				</Grid>
			</Grid>
		</div>
	);
};

export default AuthDefaultLayout;
