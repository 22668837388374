import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationsEn from "./locales/en/translation.json";
import translationsIt from "./locales/it/translation.json";
import translationsCs from "./locales/cs/translation.json";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {translation: translationsEn},
			it: {translation: translationsIt},
		},
		fallbackLng: "en",
		debug: true,
		detection: {
			order: ["navigator"],
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
