import React, {useState} from "react";
import {Alert, Button, Card, CardContent, CardHeader, Grid, TextField} from "@mui/material";
import {Form, Formik, FormikHelpers, FormikTouched} from "formik";
import {t} from "i18next";
import {Spinner} from "react-bootstrap";
import {RegistryApiService} from "../../../../services/RegistryApiService";
import {IAlert} from "../../../../types/commons";
import addOperatorInitialValues from "./FormModels/addOperatorInitialValues";
import addOperatorSchema from "./FormModels/addOperatorSchema";
import {EContactType, ERegistryType, IRegistry, IRegistryOperator} from "../../../../types/registry";

const AddUtenteNew = ({reloadTable, setReloadTable}: any) => {
	const registryApiService = new RegistryApiService();
	const [isLoading, setIsLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: t("ERRORS.ADD_OPERATOR.CREATION_ERROR"),
		dismissible: true,
		onClose: () => {
			setAlert({...alert, show: false});
		},
	});

	const initialValues = addOperatorInitialValues;
	const schema = addOperatorSchema;

	const onSubmit = (values: any, {resetForm}: FormikHelpers<any>) => {
		setIsLoading(true);
		const requestBody: Partial<IRegistry> = {
			registry_type: ERegistryType.DIPENDENTE,
			firstname: values.firstname,
			surname: values.surname,
			registry_address: [],
			fiscal_code: values.fiscal_code,
			user: {
				email: values.email,
				applicationsEnabled: ["wb"],
			},
			contact: [
				{
					contact_type: EContactType.EMAIL,
					contact: values.email,
				},
				{
					contact_type: EContactType.PHONE,
					contact: values.phone,
				},
			],
		};

		registryApiService
			.createOperator(requestBody)
			.then(() => {
				setIsLoading(false);
				setReloadTable(!reloadTable);
				resetForm();
			})
			.catch(() => {
				setIsLoading(false);
				setAlert({...alert, show: true});
			});
	};

	return (
		<div>
			<Formik<IRegistryOperator> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
				{({handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setFieldTouched}) => {
					const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IRegistryOperator>]);
					const isSubmitDisabled = !formIsTouched || !isValid;

					return (
						<Card variant="outlined">
							<CardHeader title={t("SETTINGS.TAB_USERS.ADD_USERS.TITLE")}></CardHeader>
							<CardContent>
								<Form noValidate onSubmit={handleSubmit} style={{display: "flex", justifyContent: "center", borderRight: "2px solid light grey"}}>
									{/* prima griglia */}
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12}>
											{alert.show && (
												<Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
													{t(alert.message)}
												</Alert>
											)}
										</Grid>
										<Grid item xs={12} sm={12}>
											<div>{t("SETTINGS.TAB_USERS.ADD_USERS.PERSONAL_INFO")}</div>
										</Grid>
										<Grid item xs={12} sm={4}>
											<TextField
												label={t("NAME")}
												name="firstname"
												value={values.firstname}
												variant="outlined"
												inputProps={{maxLength: 20}}
												onBlur={handleBlur}
												fullWidth
												onChange={handleChange}
												error={touched.firstname && !!errors.firstname}
												helperText={touched.firstname && (errors.firstname ? t(errors.firstname) : "")}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<TextField
												label={t("SURNAME")}
												name="surname"
												value={values.surname}
												variant="outlined"
												inputProps={{maxLength: 20}}
												onBlur={handleBlur}
												fullWidth
												onChange={handleChange}
												error={touched.surname && !!errors.surname}
												helperText={touched.surname && (errors.surname ? t(errors.surname) : "")}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<TextField
												label={t("FISCAL_CODE")}
												name="fiscal_code"
												value={values.fiscal_code.toUpperCase()}
												variant="outlined"
												inputProps={{maxLength: 50}}
												onBlur={handleBlur}
												fullWidth
												onChange={handleChange}
												error={touched.fiscal_code && !!errors.fiscal_code}
												helperText={touched.fiscal_code && (errors.fiscal_code ? t(errors.fiscal_code) : "")}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={12}>
											<div>{t("SETTINGS.TAB_USERS.ADD_USERS.CONTACTS")}</div>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label={t("EMAIL")}
												name="email"
												value={values.email}
												variant="outlined"
												inputProps={{maxLength: 50}}
												onBlur={handleBlur}
												fullWidth
												onChange={handleChange}
												error={touched.email && !!errors.email}
												helperText={touched.email && (errors.email ? t(errors.email) : "")}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											 <TextField
												label={t("PHONE")}
												name="phone"
												value={values.phone}
												variant="outlined"
												inputProps={{maxLength: 50}}
												onBlur={handleBlur}
												fullWidth
												onChange={handleChange}
												error={touched.phone && !!errors.phone}
												helperText={touched.phone && (errors.phone ? t(errors.phone) : "")}
												required
											/>
											<Grid container spacing={2} sx={{marginTop: 1}}>
												<Grid item xs={12} sm={12} sx={{display: "flex", justifyContent: "flex-end"}}>
													<Button
														disabled={isSubmitDisabled || isLoading}
														style={{backgroundColor: isSubmitDisabled ? "var(--grey-200)" : "var(--primary-500)", color: "black"}}
														variant="contained"
														type="submit"
													>
														{!isLoading ? t("CONFIRM") : <Spinner animation="border" />}
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Form>
							</CardContent>
						</Card>
					);
				}}
			</Formik>
		</div>
	);
};

export default AddUtenteNew;
