import {useEffect, useState} from "react";
import {Spinner, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";

import styles from "./InfoSegnalazione.module.css";
import {
	API_GET_TRANSLATION_REPORT_TYPE,
} from "../../api/api";
import {Translation} from "../../type";
import {t} from "i18next";
import {Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography} from "@mui/material";
import {formatDate} from "../../utils/helperFunctions";
import Topbar from "../Topbar/Topbar";
import Commenti from "../Commenti/Commenti";
import FileDownload from "../FileDownload/FileDownload";
import StatusHighlight from "../../compontensNuovi/StatusHighligt/StatusHighlight";
import Logs from "../Attività/Logs";
import {toast_error} from "../../utils/custom-toast";
import BackButton from "../../componets/Buttons/BackButton";
import BasicButton from "../../compontensNuovi/buttons/BasicButton";
import Footer from "../../componets/Footer/Footer";
import {IStatusType} from "../../types/workflow";
import {CoreApiService} from "../../services/CoreApiService";
import {IAttachment, IReport, IReportComment, IReportLog} from "../../types/report";
import {WorkflowApiService} from "../../services/WorkflowApiService";
import i18n from "../../i18n";
import {findTranslationByCode} from "../../utils/translationStateUtils";
import axios from "axios";
import { useSelector } from "react-redux";
import { EAuthSessionData } from "../../types/auth";
import NotFoundComponent from "../../componets/NotFound/NotFoundComponent";
import {ToastContainer} from "react-toastify";

const InfoSegnalazione = () => {
	const {code} = useParams<any>();
	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<IReport>();
	const [attachments, setAttachments] = useState<IAttachment[]>([]);
	const [comments, setComments] = useState<IReportComment[]>([]);
	const [logs, setLogs] = useState<IReportLog[]>([]);
	const [refreshTransition, setRefreshTransition] = useState(false);
	const [refreshComments, setRefreshComments] = useState(false);
	const [refreshReport, setRefreshReport] = useState(false);
	const [reportNotFound, setReportNotFound] = useState<boolean>(false);
	const username = useSelector((state: any) => state.user.username);

	const [openDialog, setOpenDialog] = useState(false);
	const [transitionCode, setTransitionCode] = useState("");

	const [stateTransitionType, setStateTransitionType] = useState<IStatusType[]>([]);
	const [isLoadingButtons, setIsLoadingButtons] = useState(true);
	const [translationReportType, setTranslationReportType] = useState<string>("");
	const [translationStates, setTranslationStates] = useState<Translation[]>([]);

	const fullLanguageCode = i18n.language;

	const navigate = useNavigate();

	const hasToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
	const tenant = sessionStorage.getItem(EAuthSessionData.TENANT);
	const headers = hasToken
		? {headers: {"x-access-token": hasToken, "Accept-Language": fullLanguageCode}}
		: {headers: {"x-key-code": code, "Accept-Language": fullLanguageCode}};
	const reportApiService = new CoreApiService();
	const workflowApiService = new WorkflowApiService();

	const handleBackClick = () => {
		navigate(`/TabellaSegnalazioniAdmin/`);
	};

	const reportView = async () => {
		try {
			if (hasToken) {
				await reportApiService.getReportView(code);
			} else {
				return;
			}
		} catch (error: any) {
			toast_error(error);
			setReportNotFound(true);
		}
	};

	const fetchReport = async () => {
		try {
			setIsLoading(true);
			const data = hasToken ? await reportApiService.getReport(code ?? "", tenant ?? "") : await reportApiService.getInfoReportUser(code ?? "", tenant ?? "");
			console.log("Fetch report data", data);
			if (data.report) {
				setUser(data.report);
				setAttachments(data.report.attachment);
			}
		} catch (error: any) {
			if (error.response) {
				toast_error(error.response.data.message);
			} else {
				toast_error(error.message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const fetchTranslation = async (url: string, params: any, setCallBack: any) => {
		try {
			const response = await axios.get(url, {
				headers: {
					"Accept-Language": fullLanguageCode,
				},
				params: params,
			});
			if (response.data.code === 0) {
				setCallBack(response.data.data[0].translation);
			} else {
				toast_error(response.data.message);
			}
		} catch (error: any) {
			if (error.response) {
				toast_error(error.response.data.message);
			} else {
				toast_error(error.message);
			}
		}
	};

	const fetchComments = async () => {
		try {
			const data = hasToken ? await reportApiService.getReport(code ?? "", tenant ?? "") : await reportApiService.getInfoReportUser(code ?? "", tenant ?? "");
			if (data.comments.length > 0) {
				setComments(data.comments);
			}
		} catch (error: any) {
			if (error.response) {
				toast_error(error.response.data.message);
			} else {
				toast_error(error.message);
			}
		}
	};

	const fetchLogs = async () => {
		try {
			const data = hasToken ? await reportApiService.getReport(code ?? "", tenant ?? "") : await reportApiService.getInfoReportUser(code ?? "", tenant ?? "");
			if (data) {
				const firstLog: IReportLog = {
					id_report: "",
					operation_type: "",
					username: data.report?.identification_data ? data.report?.reporter_name : t("ANONYMOUS"), // o qualsiasi username desideri mostrare
					description: t("LOGS.FIRST_LOG_DESCRIPTION"),
					createdAt: data.report.createdAt,
				};
				setLogs([...data.logs, firstLog]);
			}
		} catch (error: any) {
			if (error.response) {
				toast_error(error.response.data.message);
			} else {
				toast_error(error.message);
			}
		}	
		
	};

	useEffect(() => {
		reportView();
	}, []);

	useEffect(() => {
		fetchReport();
		fetchLogs();
	}, [refreshReport]);

	useEffect(() => {
		if (!isLoading && !user?.other_report_type) {
			console.log(user?.report_type);
			fetchTranslation(API_GET_TRANSLATION_REPORT_TYPE, {type: "report_type", code: user?.report_type.code}, setTranslationReportType);
			workflowApiService.stateTranslation({type: "state"}).then((response: any) => {
				setTranslationStates(response);
			});
		}
	}, [isLoading, user?.report_type?.code]);

	useEffect(() => {
		fetchComments();
		fetchLogs();
	}, [refreshComments]);

	useEffect(() => {
		const fetchStateTransitionType = async () => {
			try {
				setIsLoadingButtons(true); // set loading to true
				const data = await workflowApiService.getTransitionRoles();

				setStateTransitionType(data);
			} catch (error) {
				//console.error("error: ", error);
				throw error;
			} finally {
				setIsLoadingButtons(false);
			}
		};
		if (hasToken) {
			fetchStateTransitionType();
		}
	}, [refreshTransition]);

	const handleOpenDialog = (transition_code: string) => {
		setTransitionCode(transition_code);
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleChangeStateConfirmed = async () => {
		if (!hasToken || !transitionCode) {
			return;
		}
		try {
			const requestBody = {
				transition_code: transitionCode,
				username: username,
			};
			const response = await reportApiService.changeState(code ?? "", requestBody);
			// console.log("Risposta dal backend:", response);
			if (response) {
				setRefreshTransition(!refreshTransition);
				setRefreshReport(!refreshReport);
			}
		} catch (error: any) {
			if (error) {
				toast_error(error);
			} else {
				toast_error(error);
			}
		}
		handleCloseDialog();
	};

	const stateButtonsCreation = (currentState: string | undefined, stateTransition: any[]) => {
		if (isLoadingButtons) {
			return (
				<div>
					<div className={styles.subtitle}>Caricamento...</div>
					<Spinner animation="border" role="output">
						<span className="sr-only">Caricamento...</span>
					</Spinner>
				</div>
			);
		}


		const possibleTransitions = stateTransition.filter((transition) => transition.state_transition.state_from === currentState);

		if (possibleTransitions.length === 0) {
			return <div className={styles.subtitle}>{t("REPORTING.NO_STATUS_TRANSIZION_MESSAGE")}</div>;
		}

		const buttons = possibleTransitions.map((transition) => {
			const translatedStateTo = findTranslationByCode(transition.state_transition.state_to, translationStates);

			return (
				<div key={transition._id} style={{marginRight: "10px"}}>
					<div className={styles.subtitle}>{t("REPORTING.STATUS_TRANSIZION_MESSAGE")}</div>
					<Box marginTop={2}>
						<StatusHighlight
							stato={transition.state_transition.state_to}
							displayText={translatedStateTo}
							isClickable={true}
							onClick={() => handleOpenDialog(transition.state_transition.code)}
						/>
					</Box>
				</div>
			);
		});

		return buttons;
	};

	const stateTransitionButtons = stateButtonsCreation(user?.state, stateTransitionType);

	return (
		<>	
			<ToastContainer />
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<DialogTitle sx={{color: "black", fontWeight: 700}}>{t("REPORTING.DIALOG.TITLE")}</DialogTitle>
				<DialogContent>
					<DialogContentText sx={{color: "black"}}>{t("REPORTING.DIALOG.DESCRIPTION")}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<BasicButton onClick={handleChangeStateConfirmed} text={t("CONFIRM")} />

					<BasicButton
						onClick={handleCloseDialog}
						text={t("CANCEL")}
						maxWidth="fit-content"
						type="button"
						backgroundColor="trasparent"
						backgroundColorHover="var( --grey-500)"
						borderColorHover="var( --grey-500)"
						borderColor="black"
						color="black"
					/>
				</DialogActions>
			</Dialog>
			<Topbar />
			{isLoading ? (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
					}}
				>
					<CircularProgress size={100} />
				</div>
			) :(
				reportNotFound ? 
				<div style={{margin: "3%"}}>
					<Box>
						<NotFoundComponent onClickButton={() => navigate("/route-switch")} titleButton={t("GO_TO_HOME")} description={t("FORM_REPORTS.REPORT_NOT_FOUND_DESCRIPTION")} title={t("FORM_REPORTS.REPORT_NOT_FOUND")} />
					</Box>
				</div> :
				<div>
					{/* <Container maxWidth="xl" sx={{ marginTop: "5%", marginBottom: "5%" }}> */}
					<div style={{margin: "3%"}}>
						<div className={styles.labels}>
							{hasToken ? <BackButton onClick={handleBackClick} text={t("BACK")} /> : null}
							<Typography variant="h4" style={{marginTop: "20px"}}>
								{t("FORM_REPORTS.TITLE")} - {code}
								{/* ICONE */}
								{/* <span className={styles.icons}>
									<i className="fa fa-cloud-upload" style={{ marginRight: "20px" }} />
									<i className="fa fa-print" />
								</span> */}
							</Typography>
						</div>
						{/* TABELLA SEGNALATORE */}
						<Table>
							<thead
								style={{
									backgroundColor: " var(--grey-100)",
									color: "black",
									borderBottom: "2px var(--grey-100) solid",
								}}
							>
								<tr>
									<th className={styles.tableHeaders}>ID</th>
									<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.KEY_CODE")}</th>
									<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.CREATION_DATE")}</th>
									<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.REPORTER")}</th>
									<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.STATUS")}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className={styles.rowStyle}>{user?._id}</td>
									<td className={styles.rowStyle}>{user?.key_code}</td>
									<td className={styles.rowStyle}>{formatDate(user?.createdAt)}</td>
									<td className={styles.rowStyle}>{user?.identification_data ? user?.reporter_name : t("ANONYMOUS")}</td>
									<td className={styles.rowStyle} style={{width: "10px !important"}}>
										<div style={{display: "flex", alignItems: "center"}}>
											<StatusHighlight stato={user ? user.state : ""} displayText={user ? findTranslationByCode(user.state, translationStates) : ""} />
										</div>
									</td>
								</tr>
							</tbody>
						</Table>

						{user?.identification_data ? (
							<Grid container spacing={2}>
								<Grid container item xs={12} lg={6} spacing={2}>
									{/* Informazioni segnalatore */}
									<Grid item xs={12} sx={{marginTop: "3%"}}>
										<div className={styles.title}>{t("REPORTING.IDENTIFICATION_DATA_LABEL")}</div>
									</Grid>
									{/* NOME */}
									<Grid item xs={12} sm={6}>
										<div>
											<div className={styles.subtitle}>{t("REPORTING.NAME")}</div>
											<p>{user.registry?.firstname}</p>
										</div>
									</Grid>
									{/* COGNOME */}
									<Grid item xs={12} sm={6}>
										<div>
											<div className={styles.subtitle}>{t("REPORTING.SURNAME")}</div>
											<p>{user.registry?.surname}</p>
										</div>
									</Grid>
									{/* LUOGO NASCITA */}
									<Grid item xs={12} sm={6}>
										<div>
											<div className={styles.subtitle}>{t("REPORTING.LOCATION")}</div>
											{user.registry?.birth_place ? <p>{user.registry?.birth_place}</p> : <p>{t("REPORTING.NO_DATA")}</p>}
										</div>
									</Grid>
									{/* DATA DI NASCITA */}
									<Grid item xs={12} sm={6}>
										<div>
											<div className={styles.subtitle}>{t("REPORTING.BIRTH_DATE")}</div>
											{user.registry?.birth_date ? <p>{formatDate(user.registry?.birth_date)}</p> : <p>{t("REPORTING.NO_DATA")}</p>}
										</div>
									</Grid>
									{/* INDIRIZZO */}
									<Grid item xs={12} sm={6}>
										<div>
											<div className={styles.subtitle}>{t("REPORTING.ADDRESS")}</div>
											{user.registry?.id_registry_address && user.registry?.id_registry_address.length > 0 && (
												<p>{`${user.registry?.id_registry_address[0].topon} ${user.registry?.id_registry_address[0].street} ${user.registry?.id_registry_address[0].number}, ${user.registry?.id_registry_address[0].postal_code} - ${user.registry?.id_registry_address[0].city}`}</p>
											)}
											{!user.registry?.id_registry_address ||
												(user.registry.id_registry_address && user.registry.id_registry_address.length === 0 && <p>{t("REPORTING.NO_DATA")}</p>)}
										</div>
									</Grid>
									{/* CODICE FISCALE */}
									<Grid item xs={12} sm={6}>
										<div>
											<div className={styles.subtitle}>{t("REPORTING.FISCAL_CODE")}</div>
											<p>{user.registry?.fiscal_code}</p>
										</div>
									</Grid>
									{/* EMAIL */}
									<Grid item xs={12} sm={6}>
										<div>
											<div className={styles.subtitle}>{t("REPORTING.EMAIL")}</div>
											<p>{user.registry?.id_contact[0].contact}</p>
										</div>
									</Grid>
									{/* TELEFONO */}
									<Grid item xs={12} sm={6}>
										<div>
											<div className={styles.subtitle}>{t("REPORTING.PHONE")}</div>
											<p>{user.registry?.id_contact[1].contact}</p>
										</div>
									</Grid>
								</Grid>
							</Grid>
						) : null}
						{/* DATI SEGNALAZIONE */}
						{/* ===== Informazioni dell'accaduto ===== */}
						<Grid container spacing={2} sx={{marginTop: "3%"}}>
							<Grid item xs={12}>
								<div className={styles.title}>{t("REPORTING.REPORT_DETAIL")}</div>
							</Grid>
							<Grid container item xs={12} lg={6} spacing={2}>
								<Grid item xs={12}>
									<div>
										<div className={styles.subtitle}>{t("REPORTING.MISCONDUCT")}</div>
										<p>{user?.other_report_type ? user?.other_report_type_description : translationReportType}</p>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div>
										<div className={styles.subtitle}>{t("REPORTING.WHEN_REPORTING")}</div>
										<p>
											{user?.time_period_ended
												? user?.start_date && user?.end_date
													? `${formatDate(user.start_date)} - ${formatDate(user.end_date)}`
													: t("REPORTING.NOT_DECLARED")
												: user?.start_date
												? `${formatDate(user.start_date)} - ${t("ONGOING")}`
												: t("REPORTING.NOT_DECLARED")}
										</p>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div>
										<div className={styles.subtitle}>{t("REPORTING.SUBJECTS_INVOLVED")}</div>
										<p>
											{user?.subject_involved && user?.subject_involved.length > 0 ? user?.subject_involved[0].subject_involved : t("REPORTING.NOT_DECLARED")}
										</p>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div>
										<div className={styles.subtitle}>{t("REPORTING.SUBJECTS_REPORT_FACTS")}</div>
										<p>{user?.other_subjects_can_report_facts ? user?.other_subjects_can_report_facts_description : "No"}</p>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div>
										<div className={styles.subtitle}> {t("REPORTING.AUTHORITIES")}</div>
										<p>{user?.report_another_authority ? user?.report_another_authority_description : "No"}</p>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div>
										<div className={styles.subtitle}>{t("REPORTING.TALK_TO_ANYONE")}</div>
										<p>{user?.talk_with_someone_about_what_happened ? user?.talk_with_someone_about_what_happened_description : "No"}</p>
									</div>
								</Grid>
							</Grid>
							<Grid container item xs={12} lg={6} spacing={2}>
								<Grid item xs={12}>
									<div>
										<div className={styles.subtitle}>{t("FACTS_DESCRIPTION")}</div>
										<p style={{wordBreak: "break-word"}}>{user?.description}</p>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div>
										<div className={styles.subtitle}>{t("REPORTING.ATTACHMENTS")}</div>
										{attachments && attachments.length > 0 ? (
											attachments.map((attachment: any) => (
												<div key={attachment._id}>
													<FileDownload attachment={attachment} />
												</div>
											))
										) : (
											<p>{t("REPORTING.NO_ATTACHMENTS")}</p>
										)}
									</div>
								</Grid>
								{hasToken ? (
									<Grid item xs={12} sx={{marginTop: "3%"}}>
										<div>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													width: "100%",
												}}
											>
												{stateTransitionButtons}
											</div>
										</div>
									</Grid>
								) : null}
							</Grid>
						</Grid>

						{/* COMMENTI */}
						<div style={{marginTop: "50px"}}>
							<Commenti comments={comments} id={user?._id} reporterName={user?.reporter_name} setRefresh={setRefreshComments} refresh={refreshComments} />
						</div>

						{/* LOGS */}
						{logs.length > 0 ? (
							<div style={{marginTop: "50px"}}>
								<Logs logs={logs} />
							</div>
						) : null}
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default InfoSegnalazione;
